
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { FormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';
import {appRoutes} from './app.routing';
import {HeaderComponent, SidebarComponent, ConfirmComponent, AccountComponent} from '@app/components';
import {CoreModule, SharedModule} from '@app/modules';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {HttpInteceptor, AuthService} from '@app/services';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    HeaderComponent,
    ConfirmComponent,
    AccountComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MDBBootstrapModule.forRoot(),
    NgbModule,
    FormsModule,
    SharedModule,
    CoreModule,
    RouterModule.forRoot(appRoutes, { useHash: true, onSameUrlNavigation: 'reload' })
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInteceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
