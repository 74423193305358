import {Directive, HostListener, Input} from '@angular/core';
import {Router} from '@angular/router';
import {ROUTES} from '@app/config';
import {NavigationHelper} from "@app/helpers";

@Directive({
    selector: '[custom-routerLink]'
})

export class RouterlinkCustom {
    constructor(private _router: Router, private _navigationHelper: NavigationHelper) {
    }

    @Input('custom-routerLink') routerLink: any;

    @HostListener('click') onClick() {
        if (ROUTES[this.routerLink] && ROUTES[this.routerLink]['reload']) {
            window.location.href = '/#' + ROUTES[this.routerLink].route;
            window.location.reload();
        } else {
          this._router.navigateByUrl('/loading', {skipLocationChange: true})
            .then(() => {
              this._navigationHelper.navigateTo(this.routerLink);
            });
        }
    }
}

