import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Config} from '@app/config';
import {Observable} from 'rxjs';
import {MoyenModel} from '@app/models';

@Injectable({
  providedIn: 'root',
})

export class MoyenService {

  constructor(private _http: HttpClient) {
  }

  getMoyens(page = 1): Observable<any> {
    return this._http.get(Config.api.moyen.all + '&page=' + page);
  }

  getShortList(): Observable<any> {
    return this._http.get(Config.api.moyen.shortlist);
  }

  getMoyensForSelect(): Observable<any> {
    return this._http.get(Config.api.moyen.list);
  }

  createMoyen(moyen: MoyenModel): Observable<any> {
    return this._http.post(Config.api.moyen.create, moyen);
  }

  updateMoyen(moyen: MoyenModel): Observable<any> {
    return this._http.post(Config.api.moyen.update, moyen);
  }

  deleteMoyen(id_moyen: any): Observable<any> {
    return this._http.post(Config.api.moyen.delete, id_moyen);
  }

}
