/*
  Liste des noms des routes pour changer l'envoi des lien par nom de route et non pas par route
  path: [la route définit dans dans le fichier routing],
  route: [La route pour la navigation],
  name: [Nom de la route],
*/
export const ROUTES = {
  'tour': {
    path: 'mission',
    route: '/mission',
    name: 'tour'
  },
  'tour.detail': {
    path: 'detail',
    route: '/mission/detail',
    name: 'tour.detail'
  },
  'tour.tournee': {
    path: 'tournee',
    route: '/mission/tournee',
    name: 'tour.tournee'
  },
  'tour.import': {
    path: 'import',
    route: '/mission/import',
    name: 'tour.import'
  },
  'auth': {
    path: 'auth',
    route: '/auth',
    name: 'auth'
  },
  'login': {
    path: 'login',
    route: '/auth/login',
    name: 'login'
  },
  'reset': {
    path: 'password/reset',
    route: '/auth/password/reset',
    name: 'reset'
  },
  'send': {
    path: 'password/send',
    route: '/auth/password/send',
    name: 'send'
  },
  '404': {
    path: '404',
    route: '/404',
    name: '404'
  },
  'detail': {
    path: 'detail',
    route: '/tour/detail',
    name: 'detail'
  },
  'user': {
    path: 'user',
    route: '/user',
    name: 'user'
  },
  'depot': {
    path: 'depot',
    route: '/depot',
    name: 'depot'
  },
  'moyen': {
    path: 'moyen',
    route: '/moyen',
    name: 'moyen'
  },
  'prestataire': {
    path: 'prestataire',
    route: '/prestataire',
    name: 'prestataire'
  },
  'incident': {
    path: 'incident',
    route: '/incident',
    name: 'incident'
  },
  'client': {
    path: 'client',
    route: '/client',
    name: 'client'
  },
  'encombrant': {
    path: 'encombrant',
    route: '/encombrant',
    name: 'encombrant'
  },
  'notification': {
    path: 'notification',
    route: '/notification',
    name: 'notification'
  },
  'notification.detail': {
    path: 'detail',
    route: '/notification/detail',
    name: 'notification.detail'
  },
  'scheduler': {
    path: 'scheduler',
    route: '/scheduler',
    name: 'scheduler',
    reload :true,
  },
  'scheduler-textile': {
    path: 'scheduler/textile',
    route: '/scheduler/textile',
    name: 'scheduler-textile',
    reload :true,
  },
  'terminal': {
    path: 'terminal',
    route: '/terminal',
    name: 'terminal'
  },
  'rdv': {
    path: 'rdv',
    route: '/rdv',
    name: 'rdv'
  },
  'rdv.list': {
    path: 'list',
    route: '/rdv/list',
    name: 'rdv.list',
    reload:true
  },
  'rdv.aquity': {
    path: 'aquity',
    route: '/rdv/aquity',
    name: 'rdv.aquity'
  },
  'rdv.client': {
    path: 'client',
    route: '/rdv/client',
    name: 'rdv.client' /* ce nom est utilisé pour la vérification du route nécessitant la saisie de l'email du client avant consultation */
  },
  'rdv.address': {
    path: 'address',
    route: '/rdv/address',
    name: 'rdv.address'
  },
  'rdv.feedback': {
    path: 'feedback',
    route: '/rdv/feedback',
    name: 'rdv.feedback'
  },
  'holiday' : {
    path: 'holiday',
    route: '/holiday',
    name: 'holiday'
  },
  'loading' : {
    path: 'loading',
    route: '/loading',
    name: 'loading'
  },
  'bailleur': {
    path: 'bailleur',
    route: '/bailleur',
    name: 'bailleur'
  },
  'gardien': {
    path: 'gardien',
    route: '/gardien',
    name: 'gardien'
  },
  'adresse.import': {
    path: 'adresse/import',
    route: '/adresse/import',
    name: 'adresse.import'
  },
  'stat.bilan': {
    path: 'statistique/bilan',
    route: '/statistique/bilan',
    name: 'stat.bilan'
  },

};
