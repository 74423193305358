// Liste des menus pour les titre, sidebar et breadcrumb
import {ROUTES} from "./routes.config";
import { environment } from "environments/environment";

export const MENU = [
  {
    // Détails mission
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER','GESTIONNAIRE'],
    sidebarName: "Planification",
    routeName: ROUTES['scheduler'].name,
    parentMenu: ROUTES['scheduler'].name,
    icon: "Planification",
    name: "Planification",
    hasBreadcrumb: false,
    breadcrumb: ['Dashboard', 'Pages', 'Planification']
  },
  {
    // Détails mission
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER','GESTIONNAIRE'],
    sidebarName: "Planification textile",
    routeName: ROUTES['scheduler-textile'].name,
    parentMenu: ROUTES['scheduler-textile'].name,
    icon: "Planification",
    name: "Planification textile",
    hasBreadcrumb: false,
    breadcrumb: ['Dashboard', 'Pages', 'Planification textile']
  },
  {
    // Missions et tournées
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'PRESTATAIRE','GESTIONNAIRE'],
    sidebarName: "Missions et tournées",
    routeName: ROUTES['tour'].name,
    parentMenu: ROUTES['tour'].name,
    icon: "tour",
    name: "Missions et tournées",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Missions et tournées']
  },
  {
    // Détails mission
    isMenu: false,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'PRESTATAIRE','GESTIONNAIRE'],
    sidebarName: "Détails mission",
    routeName: ROUTES['tour.detail'].name,
    parentMenu: ROUTES['tour.detail'].name,
    icon: "tour",
    name: "Détails mission",
  },
  {
    // Détails tournee
    isMenu: false,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'PRESTATAIRE','GESTIONNAIRE'],
    sidebarName: "Détails Tournée",
    routeName: ROUTES['tour.tournee'].name,
    parentMenu: ROUTES['tour.tournee'].name,
    icon: "import",
    name: "Détails Tournée",
  },
  {
    // RDV
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'AGENT', 'MANAGER','GESTIONNAIRE'],
    sidebarName: "Prendre un RDV",
    routeName: ROUTES['rdv'].name,
    parentMenu: ROUTES['rdv'].name,
    icon: "prendre-rdv",
    name: "Prendre un RDV",
    hasBreadcrumb: false,
    breadcrumb: ['Dashboard', 'Pages', 'Prendre un RDV']
  },
  {
    // RDV
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'AGENT', 'MANAGER', 'POLICE', 'PRESTATAIRE','GESTIONNAIRE'],
    sidebarName: "Liste des RDV",
    routeName: ROUTES['rdv.list'].name,
    parentMenu: ROUTES['rdv.list'].name,
    icon: "menu-button",
    name: "Liste des RDV",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Liste des RDVs']
  },
  {
    // Gestion des incidents
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'PRESTATAIRE'],
    sidebarName: "Gestion des incidents",
    routeName: ROUTES['incident'].name,
    parentMenu: ROUTES['incident'].name,
    icon: "incidents",
    name: "Gestion des incidents",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des incidents']
  },
  {
    // Gestion des clients
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Gestion des usagers",
    routeName: ROUTES['client'].name,
    parentMenu: ROUTES['client'].name,
    icon: "user-shape",
    name: "Gestion des usagers",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des usagers']
  },
  {
    // Gestion des gardiens
    isMenu: environment.menuGardienVisible,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Gestion des gardiens",
    routeName: ROUTES['gardien'].name,
    parentMenu: ROUTES['gardien'].name,
    icon: "group-profile-users",
    name: "Gestion des gardiens",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des gardiens']
  },
  {
    // Gestion des bailleurs
    isMenu: environment.menuGardienVisible,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Gestion des bailleurs",
    routeName: ROUTES['bailleur'].name,
    parentMenu: ROUTES['bailleur'].name,
    icon: "dumpster",
    name: "Gestion des bailleurs",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des bailleurs']
  },
  {
    // Gestion des prestataires
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER'],
    sidebarName: "Gestion des prestataires",
    routeName: ROUTES['prestataire'].name,
    parentMenu: ROUTES['prestataire'].name,
    icon: "prestataires",
    name: "Gestion des prestataires",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des prestataires']
  },
  {
    // Gestion des moyens
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER'],
    sidebarName: "Gestion des moyens",
    routeName: ROUTES['moyen'].name,
    parentMenu: ROUTES['moyen'].name,
    icon: "moyens",
    name: "Gestion des moyens",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des moyens']
  },
  {
    // Gestion d'encombrant
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Gestion d'encombrants",
    routeName: ROUTES['encombrant'].name,
    parentMenu: ROUTES['encombrant'].name,
    icon: "encombrant",
    name: "Gestion d'encombrants",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion d\'encombrants']
  },
  {
    // Gestion des dépôts
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Gestion des dépôts",
    routeName: ROUTES['depot'].name,
    parentMenu: ROUTES['depot'].name,
    icon: "dpts",
    name: "Gestion des dépôts",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des dépôts']
  },
  {
    // Gestion des terminaux
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Gestion des terminaux",
    routeName: ROUTES['terminal'].name,
    parentMenu: ROUTES['terminal'].name,
    icon: "terminaux",
    name: "Gestion des terminaux",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des terminaux']
  },
  {
    // Gestion des utilisateurs
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Gestion des utilisateurs",
    routeName: ROUTES['user'].name,
    parentMenu: ROUTES['user'].name,
    icon: "utilisateurs",
    name: "Gestion des utilisateurs",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Gestion des utilisateurs']
  },
  {
    // Mail et SMS
    isMenu: false,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Mail et SMS",
    routeName: ROUTES['notification'].name,
    parentMenu: ROUTES['notification'].name,
    icon: "sms",
    name: "Mail et SMS",
  },
  {
    // Mail et SMS
    isMenu: false,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Détail mail et sms",
    routeName: ROUTES['notification.detail'].name,
    parentMenu: ROUTES['notification.detail'].name,
    icon: "sms",
    name: "Détail mail et sms",
  },
  {
    // Import de tournée fixes
    isMenu: false,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Import de tournée fixes",
    routeName: ROUTES['tour.import'].name,
    parentMenu: ROUTES['tour.import'].name,
    icon: "import",
    name: "Import de tournée fixes",
  },
  {
    isMenu: false,
    roles: [],
    routeName: ROUTES['rdv.client'].name,
    parentMenu: ROUTES['rdv.client'].name,
    name: "Vallée Sud Recycle - Prise de Rdv",
    hasBreadcrumb: false,
    fullTitle : true
  },
  {
    // Statistique
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Statistique & Bilan",
    routeName: ROUTES['stat.bilan'].name,
    parentMenu: ROUTES['stat.bilan'].name,
    icon: "bar-graph-on-a-rectangle1",
    name: "Statistique & Bilan",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Bilan']
  },
  {
    // Adresse
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN'],
    sidebarName: "Import d'adresses",
    routeName: ROUTES['adresse.import'].name,
    parentMenu: ROUTES['adresse.import'].name,
    icon: "cloud-storage-uploading-option1",
    name: "Adresses",
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Importer d\'adresses']
  },
  {
    // Jours féries
    isMenu: true,
    roles: ['ADMIN', 'SUPER_ADMIN', 'MANAGER', 'PRESTATAIRE'],
    sidebarName: 'Jours féries',
    routeName: ROUTES['holiday'].name,
    parentMenu: ROUTES['holiday'].name,
    icon: 'holiday',
    name: 'Jours féries',
    hasBreadcrumb: true,
    breadcrumb: ['Dashboard', 'Pages', 'Jours féries']
  }
];
