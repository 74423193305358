import {ENCOMBRANT_TYPES} from '@app/config';

export class EncombrantModel {
  public id: number;
  public id_encombrant: number;
  public nom: string;
  public description: string;
  public img_name: string;
  public volume: string;
  public qte: number;
  public code: string;
  public category: string;
  public qte_max_par_rdv: number;
  public nbr_rdv_par_dispo: number = 0;
  public front_pres_ref?: string = null;
  public typologies: any[];

  constructor(encombrant: any) {
    this.id = encombrant.id_encombrant;
    this.id_encombrant = encombrant.id_encombrant;
    this.nom = encombrant.nom;
    this.description = encombrant.description;
    this.img_name = encombrant.img_name;
    this.volume = encombrant.volume;
    this.qte = 0;
    this.code = encombrant.code;
    this.category = encombrant.category;
    this.qte_max_par_rdv = encombrant.qte_max_par_rdv;
    this.nbr_rdv_par_dispo = encombrant.nbr_rdv_par_dispo ? encombrant.nbr_rdv_par_dispo : 0;
    this.front_pres_ref = encombrant.front_pres_ref;
    this.typologies = encombrant.typologies ? encombrant.typologies : [];
  }

  // Retourne une liste des encombrants
  static ToEncombrantArray(data: EncombrantModel[] = []): EncombrantModel[] {
    const encombrants: EncombrantModel[] = [];
    data.forEach(item => {
      const encombrant: EncombrantModel = new EncombrantModel(item);
      encombrants.push(encombrant);
    });
    return encombrants;
  }


  toJson() {
    return {
      id_encombrant: this.id,
      nom: this.nom,
      category: this.category,
      description: this.description,
      img_name: '',
      volume: this.volume,
      qte_max_par_rdv:this.qte_max_par_rdv,
      nbr_rdv_par_dispo:this.nbr_rdv_par_dispo,
      front_pres_ref:this.front_pres_ref,
      typologies:this.typologies
    };
  }
}
