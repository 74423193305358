import {Component, OnDestroy, OnInit} from '@angular/core';
import {MoyenModel} from '@app/models';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ConfirmService, MoyenService, PrestataireService} from '@app/services';
import {map} from 'rxjs/internal/operators';
import {closeAllModals, Required,TypeHelper} from '@app/helpers';
import {RESPONSE} from '@app/config';
import {Unsubscribe} from '@app/decorators';
import { Prestataire } from '../scheduler/models/prestataire';

@Component({
  selector: 'app-moyen',
  templateUrl: './moyen.component.html',
  styleUrls: ['./moyen.component.scss']
})

@Unsubscribe()
export class MoyenComponent implements OnInit, OnDestroy {
  moyensList: MoyenModel[];
  prestataireList: Prestataire[];
  selectedMoyen: MoyenModel;
  isLoading = true;
  moyenForm: FormGroup;
  moyenPagination: any;
  moyenTypes = [];
  subscription = new Subscription();
  page: number;
  notAuthorized = false;
  isRequesting = false;

  constructor(
    private _moyenService: MoyenService,
    private _prestataireService: PrestataireService,
    private _confirmService: ConfirmService,
    private typeHelper: TypeHelper
  ) { }

  ngOnInit() {
    this.triggerConfirmationEvent();
    this.setMoyenForm();
    this.getMoyensListe();
    this.getPrestataireList();
    //récupérer les types
    this.getTypes();
  }

  triggerConfirmationEvent() {
    const request =  this._confirmService.answer$.subscribe((response) => {
      if (response && response.action === 'moyen.delete' && response.value) {
        this.deleteMoyen(this.selectedMoyen.id);
        this._confirmService.answer = null;
      }
    });
    this.subscription.add(request);
  }

  setMoyenForm() {
    if (this.selectedMoyen === undefined) {
      this.moyenForm = new FormGroup({
        id_moyen: new FormControl('', ),
        libelle: new FormControl('', [Required.validator]),
        type: new FormControl('', [Required.validator]),
        volume: new FormControl('', [Required.validator, Validators.pattern('^[+]?([0-9]{1,1})*([.,][0-9]{1,2})?$')]),
        poids_max: new FormControl('', [Required.validator, Validators.pattern('^[+]?([0-9]{1,1})*([.,][0-9]{1,2})?$')]),
        id_prestataire: new FormControl('', [Required.validator]),
      });
    } else if (this.selectedMoyen === null) {
      const emptyMoyen = {
        id_moyen: '',
        libelle: '',
        type: '',
        volume: '',
        poids_max: '',
        id_prestataire: ''
      };
      this.moyenForm.setValue(emptyMoyen);
    } else {
      // this.selectedMoyen['id_prestataire'] = this.selectedMoyen.prestataires.length ? this.selectedMoyen.prestataires[0].id_prestataire : '';
      // console.log("1",this.selectedMoyen.prestataires.length)
      // this.moyenForm.setValue({'id_prestataire': this.selectedMoyen.prestataires.length ? this.selectedMoyen.prestataires[0].id_prestataire : ''});
      this.moyenForm.setValue(this.selectedMoyen.toJson());
    }
  }

  getMoyensListe(page = 1) {
    this.isLoading = true;
    this.page = page;
    const request =  this._moyenService.getMoyens(this.page)
      .subscribe(
        response => {
          this.isLoading = false;
          this.moyensList = MoyenModel.ToMoyenArray(response.payload.data);
          this.moyenPagination = {
            id: 'old',
            itemsPerPage: response.payload.per_page,
            currentPage: response.payload.current_page,
            totalItems: response.payload.total
          };
        }
      );
    this.subscription.add(request);
  }

  getPrestataireList() {
    const request =  this._prestataireService.getPrestatairesForSelect()
      .subscribe(
        data => this.prestataireList = data.payload
      );
    this.subscription.add(request);
  }

  setSelectedMoyen(moyen: any = null) {
    this.isRequesting = false;
    this.notAuthorized = false;
    this.selectedMoyen = moyen ? moyen as MoyenModel : null;
    this.setMoyenForm();
  }

  saveMoyen() {
    this.isRequesting = true;
    const params = this.moyenForm.value;
    let request;
    if (this.selectedMoyen) {
      request = this._moyenService.updateMoyen(params);
    } else {
      delete params['id_moyen'];
      request = this._moyenService.createMoyen(params);
    }

    const ask =  request
      .subscribe(
        (data) => {
          this.handleResponse(data._response);
        },
        (error) => {
          this.isRequesting = false;
          this.handleError(error);
        }
      );
    this.subscription.add(ask);
  }

  handleError(error) {
    this.notAuthorized = false;
    if ( error._response.code === RESPONSE.MOYEN.ERROR.NOT_AUTHORIZED ) {
      this.notAuthorized = true;
    }
  }

  deleteMoyen(id) {
    const params = { id_moyen: id};
    const request =  this._moyenService.deleteMoyen(params)
      .pipe(
        map((data) => {
          this.handleResponse(data._response);
        })
      )
      .subscribe();
    this.subscription.add(request);
  }

  handleResponse(response) {
    if ([RESPONSE.MOYEN.OK.CREATE, RESPONSE.MOYEN.OK.UPDATE, RESPONSE.MOYEN.OK.DELETE, RESPONSE.MOYEN.OK.DISPONIBILITE].indexOf(response.code) !== -1) {
      if (RESPONSE.MOYEN.OK.DELETE === response.code) {
        this.checkPageIfEmpty();
      }
      this.getMoyensListe(this.page);
      closeAllModals();
      this.moyenForm.reset();
    }
  }

  showConfirm(moyen: MoyenModel) {
    this.notAuthorized = false;
    this.setSelectedMoyen(moyen);
    this._confirmService.showConfirm('moyen.delete', 'Êtes vous sûr de vouloir supprimer cet élement ?');
  }

  isFormInvalid() {
    return this.moyenForm.invalid || !this.moyenForm.dirty;
  }

  checkPageIfEmpty() {
    if (this.moyensList.length === 1) {
      this.page = (this.page > 1) ? this.page - 1 : this.page;
    }
  }

  onPageChange(page: any): void {
    this.page = page;
    this.getMoyensListe(this.page);
  }

  getTypes(){
    this.typeHelper.getTypes().subscribe(types=>{
        this.moyenTypes=types;
    });

  }

  ngOnDestroy() {}
}
