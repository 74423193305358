import {Component, OnDestroy, OnInit} from '@angular/core';
import {IncidentService, MissionService, StatistiqueService} from '@app/services';
import {ActivatedRoute} from '@angular/router';
import {RESPONSE, ROUTES, WORKFLOWLIST} from '@app/config';
import {NavigationHelper} from '@app/helpers';
import {Ramassage} from '@app/models';
import {Unsubscribe} from '@app/decorators';
import * as moment from 'moment';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-tournee',
  templateUrl: './tournee.component.html',
  styleUrls: ['./tournee.component.scss']
})

@Unsubscribe()
export class TourneeComponent implements OnInit, OnDestroy {

  params = {id_tournee: this._route.snapshot.params.id_tournee}
  mission: any = {};
  isLoading = false;
  qrcodeVar: any = null;
  _WORKFLOWLIST = WORKFLOWLIST;
  _workflowdone: any[] = [];
  isLoading_workflow: boolean;
  origin: any = {};
  destination: any = {};
  waypoints = [];
  selectedRamassage = [];
  Logettes: any;
  infoWindowOpened = null;
  previous_info_window = null;
  selectedRamassageIndex: number;
  renderOptions = {
    suppressMarkers: true,
  };
  incidents:any[]=[];
  lastPosition = null;
  positionInterval = null;
  map: any;
  selectedImage=[];
  typeImage='incident';
  selectedLon;
  selectedLat;
  selectedRamObject;
  ramassage_count = 0;

  map_pins = null;

  constructor(
    private _missionService: MissionService,
    private _route: ActivatedRoute,
    private _navigationHelper: NavigationHelper,
    //private _logetteService: LogetteService,
    private _incidentService: IncidentService,
    private _statistiqueService: StatistiqueService) {
  }

  ngOnInit() {
    if (this.mission.statut === 'EN_COURS') {
      this.getLastPosition();
      this.positionInterval = setInterval(() => {
        this.getLastPosition();
      }, 5000);
    }

    this.getTournee();
    //this.getLogettes();
  }

  mapReady(map) {
    this.map = map;
  }

  getLastPosition() {
    if (this.mission && this.mission.itineraire && this.mission.itineraire.length) {
      const position = this.mission.itineraire[this.mission.itineraire.length - 1];
      moment.locale('fr');
      this.lastPosition = {
        lat: position.lat,
        lng: position.lon,
        date: moment(position.date, "YYYY/MM/DD hh:mm:ss").fromNow()
      };
    }
  }

  showLastPosition() {
    this.getLastPosition();
    this.map.setCenter({ lat: this.lastPosition.lat, lng: this.lastPosition.lng });
    this.map.setZoom(18);
  }

  getIncidents(ram){
    let params = { id_ramassage:ram.id_ramassage };
    this.selectedRamObject=ram;
    this._incidentService.getIncidentsByRamassage(params).subscribe(
      (response)=>{
        this.incidents = response.payload;
      }
    )
  }

  getIncidentsByTournee(id_tournee: number) {
    let params = { id_tournee: id_tournee };
    this._incidentService.getIncidentsByTournee(params).subscribe(
      (response)=>{
        this.incidents = response.payload;
      }
    )
  }

  close_window() {
    if (this.previous_info_window != null) {
      this.previous_info_window.close();
    }
  }

  isEmpty(obj) {
    return Object.entries(obj).length === 0 && obj.constructor === Object;
  }

  select_marker(infoWindow) {
    if (this.previous_info_window == null) {
      this.previous_info_window = infoWindow;
    }
    else {
      this.infoWindowOpened = infoWindow;
      this.previous_info_window.close();
    }
    this.previous_info_window = infoWindow;
  }

  getUrlMarker(index, ramassage) {
    let dimension = 0.8;//ramassage.increase * 0.8;
    let rotation = 0;//ramassage.increase * 45;
    let selected_pin,default_pin,canceled_pin;
    //let counter = parseInt(index+1); ///ramassage.ordre || ''
    if(ramassage.indexes.length == 1){
      //console.log("getUrlMarker A",ramassage)
      selected_pin = 'https://chart.googleapis.com/chart?chst=d_map_spin&chld='+dimension+'|'+rotation+'|27ae60|15|b|'+ramassage.texts.join(',');
      default_pin = 'https://chart.googleapis.com/chart?chst=d_map_spin&chld='+dimension+'|'+rotation+'|d4101f|15|b|'+ramassage.texts.join(',');
      canceled_pin = 'https://chart.googleapis.com/chart?chst=d_map_spin&chld='+dimension+'|'+rotation+'|999|15|b|'+ramassage.texts.join(',');
    }
    else/* if(ramassage.increase == 1)*/{
      //console.log("getUrlMarker B",ramassage)
      selected_pin = 'https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bbtr|'+ramassage.texts.join(',')+'|27ae60|000000'
      default_pin = 'https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bbtr|'+ramassage.texts.join(',')+'|d4101f|000000'
      canceled_pin = 'https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bbtr|'+ramassage.texts.join(',')+'|999|000000'
    }
    /*else{
      selected_pin = 'https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|R'+ramassage.texts.join(',')+'|27ae60|000000'
      default_pin = 'https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|R'+ramassage.texts.join(',')+'|d4101f|000000'
      canceled_pin = 'https://chart.googleapis.com/chart?chst=d_bubble_text_small&chld=bb|R'+ramassage.texts.join(',')+'|999|000000'
    }*/

    let icon = '';
    if (index === this.selectedRamassageIndex) {
      icon = selected_pin;
    } else if (ramassage.statut === "ANNULE") {
      icon = canceled_pin;
    } else  {
      icon = default_pin;
    }
    return icon;
  }

  // getLogettes() {
  //   this._logetteService.getLogettes().subscribe(
  //     (response) => {
  //       this.Logettes = response.payload.data;
  //     }
  //   );
  // }

  getPointsByType(type) {
    const points  = this.mission.v_ramassages.filter(item => item.statut !== 'ANNULE');
    const count = points.length;
    const point = {lat: 0, lng: 0};
    if (type === 'origin') {
      point.lat = count && count > 1 ? points[0].lat : point.lat;
      point.lng = count && count > 1 ? points[0].lon : point.lng;
    } else {
      point.lat = count && count > 1 ? points[count - 1].lat : point.lat;
      point.lng = count && count > 1 ? points[count - 1].lon : point.lng;
    }
    point.lat = Number.parseFloat(point.lat.toString());
    point.lng = Number.parseFloat(point.lng.toString());
    return point;
  }

  getTournee() {
    this.isLoading = true;
    this._missionService.getTournee(this.params).subscribe(
      (response) => {
        this.mission = response.payload;
        this.mission['v_ramassages'] = this.mission['v_ramassages'].filter(e => e.statut != 'ANNULE').sort((a, b) => {return a.ordre - b.ordre})

        this.origin = {
          lat: this.parseNumber(((this.mission ? this.mission.depot_depart : false) ? this.mission.depot_depart.lat : false) ? this.mission.depot_depart.lat : 0, true),
          lng: this.parseNumber(((this.mission ? this.mission.depot_depart : false) ? this.mission.depot_depart.lon : false) ? this.mission.depot_depart.lon : 0, true)
        };
        this.destination = {
          lat: this.parseNumber(((this.mission ? this.mission.depot_arrive : false) ? this.mission.depot_arrive.lat : false) ? this.mission.depot_arrive.lat : 0, true),
          lng: this.parseNumber(((this.mission ? this.mission.depot_arrive : false) ? this.mission.depot_arrive.lon : false) ? this.mission.depot_arrive.lon : 0, true)
        };
        this.origin =  (this.origin.lat && this.origin.lat) ? this.origin : this.getPointsByType('origin');
        this.destination = (this.destination.lat && this.destination.lat) ? this.destination : this.getPointsByType('destination');
        const depart = {...this.origin, ...{data: this.mission.depot_depart ? this.mission.depot_depart[0] : null }};
        const arrive = {...this.destination, ...{data: this.mission.depot_arrive ? this.mission.depot_arrive[0] : null }};
        this.mission['waypoints'] = [depart, arrive];

        let lastLatLong = null;

        this.map_pins = [];
        this.mission.v_ramassages.forEach((elem, index) => {
            elem.index = index+1;
            elem.txt ='R'+elem.index;

            if(lastLatLong != elem.lat+'|'+elem.lon){
              this.map_pins.push(this.getMapPinData(elem));
            }else {
              let ram_destination = this.map_pins.find( m => m.geoId === lastLatLong);
              this.pushToMapPinData(ram_destination,elem);
            }

            lastLatLong=elem.lat+'|'+elem.lon;

            this.waypoints.push({
              location: {
                lat: Number.parseFloat(elem.lat),
                lng: Number.parseFloat(elem.lon),
              }, stopover: false
            });
        });

        this.ramassage_count = this.mission.v_ramassages.length;
        this.isLoading = false;
      }, (error) => {
        this.handleResponse(error._response);
      }
    );
  }

  getMapPinData(ram){
    return {indexes:[ram.index],texts:[ram.txt],codes:[ram.code],lat:ram.lat,lon:ram.lon,retardes_le:[ram.retarde_le],geoId:ram.lat+'|'+ram.lon};
  }

  pushToMapPinData(destination,source){
    destination.indexes.push(source.index);
    destination.codes.push(source.code);
    destination.texts.push(source.txt);
    destination.retardes_le.push(source.retarde_le);
  }

  handleResponse(response: any) {
    if (response.code === RESPONSE.MISSION.ERROR.GET) {
      this._navigationHelper.navigateTo(ROUTES['tour'].name);
    }
  }

  getStatutWorkflow(ramassage) {
    return Ramassage.getStatutWorkflow(ramassage);
  }

  getStatusRamassage(ramassage) {
    return Ramassage.getStatusRamassage(ramassage);
  }

  getStatutRamassageArray(arrayRamassage) {
    return Ramassage.getStatutRamassageArray(arrayRamassage);
  }

  totalVolume(encombrants: any[]) {
    let somme = 0;
    encombrants.forEach(elem => {
      somme += Number(elem.volume) * Number(elem.qte);
    });
    return Number(somme.toFixed(2));
  }

  totalVolumeRam(ramassages:any[]){
    let res = 0;
    ramassages.forEach((elem)=>{
      res += elem.statut == 'ANNULE' ? 0 : this.totalVolume(elem.encombrants);
    });
    return Number(res.toFixed(2));
  }

  goBack() {
    this._navigationHelper.navigateTo(ROUTES['tour'].name);
  }

  showRamassage(codes: any, index: number) {
    this.selectedRamassage = codes;
    this.selectedRamassageIndex = index;
  }

  colorWorkflow() {
    if (!this.isLoading_workflow) {
      let array = [];
      let enCours = false;
      this._WORKFLOWLIST.forEach((elem, index) => {
        if (this._workflowdone.filter(item => {
          return elem.key === item.statut;
        }).length) {
          this._workflowdone = this._workflowdone.filter(item => {
            return elem.key != item.statut;
          });
          elem.color = 'gray';
          elem.etat = 'Validé';
          elem.icon = 'valide';
          array.push(elem);
        } else if (this._workflowdone.length) {
          elem.color = 'gray';
          elem.etat = 'Échappé';
          elem.icon = 'en-cours';
          array.push(elem);
        } else {
          if (!enCours) {
            elem.color = 'vert';
            elem.etat = 'En cours';
            elem.icon = 'en-cours';
            array.push(elem);
          } else {
            elem.color = 'jaune';
            elem.etat = 'En attente';
            elem.icon = 'en-attente';
            array.push(elem);
          }
          enCours = true;
        }
      });
    }
  }

  workflowShowBy(ram) {
    this._WORKFLOWLIST.forEach((item) => {
      item.image = null;
    });
    this.isLoading_workflow = true;
    const params = {id_ramassage: ram.id_ramassage};
    this.selectedRamObject=ram;
    this._missionService.workflowShowBy(params).subscribe(
      (response) => {
        this._workflowdone = response.payload;
        this._workflowdone.forEach((elem) => {
          this._WORKFLOWLIST.forEach((item) => {
            if (item.key === elem.statut) {
              if (elem.images[0] && elem.images[0].image) {
                item.image = elem.images[0].image;
                item.date = elem.images[0].created_at;
                item.lat = elem.images[0].lat;
                item.lon = elem.images[0].lon;
                item.distance = elem.images[0].distance;
                item.depasse_limit = elem.images[0].depasse_limit;
                item.unite = elem.images[0].unite;
              }
            }
          });
        });
        this.isLoading_workflow = false;
        if (this._workflowdone != null) {
          this.colorWorkflow();
        }
      }
    );
  }

  parseNumber(variable, fixed = false) {
    return fixed ? Number(parseFloat(variable)) : Number(parseFloat(variable).toFixed(2));
  }

  stringToNumber(variable){
    return Number(variable);
  }

  sameDepot(mission_waypoints){
    let equal = true;
    let fisrtItem = mission_waypoints[0];
    mission_waypoints.forEach(elem=>{
      if(elem.lat != fisrtItem.lat || elem.lng != elem.lng){
        equal = false;
      }
    });
    return equal;
  }

  reload() {
    this.getTournee();
    //this.getLogettes();
  }

  ngOnDestroy() {
    clearInterval(this.positionInterval);
  }

  // récupérer le fichier pdf de la tournee
  getTourneePdf(idTournee) {
    const params = {
       tournee: 1,
       id_tournee: idTournee
    };
    return this._statistiqueService.getMissionPdf(params).subscribe(
      response => {
        const blob = new Blob([response], { type: 'application/pdf' });
        FileSaver.saveAs(blob, 'tournee.pdf');
      }
    );
  }

  setSelectedImage(image){
    this.selectedImage=[image];
    this.selectedLon=this.selectedRamObject?this.selectedRamObject.lon:null;
    this.selectedLat=this.selectedRamObject?this.selectedRamObject.lat:null;
    this.typeImage='workflow';
  }

  setSelectedImageIncident(event){
    this.selectedImage=event;
    this.selectedLon=this.selectedRamObject?this.selectedRamObject.lon:null;
    this.selectedLat=this.selectedRamObject?this.selectedRamObject.lat:null;
    this.typeImage='incident';
  }
}
