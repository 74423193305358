import * as moment from 'moment';
import {JourFeries} from './jourFeries';

declare var $: any;

export function closeAllModals() {
  $('.modal').modal('hide');
}

export function validate(evt) {
  const theEvent = evt || window.event;
  if (!((evt.keyCode >= 48 && evt.keyCode <= 57) || (evt.keyCode >= 96 && evt.keyCode <= 105)) && evt.keyCode !== 8) {
    theEvent.returnValue = false;
    if (theEvent.preventDefault) theEvent.preventDefault();
  }
}

export function getDaysFromDisponibilites(disponibilites: any[] = [], start_date: string,category, selectedAdresse:any = null) {
  moment.locale('fr');

  const dispos = {
    days: [],
    morning: [],
    morningP2: [],
    evening: []
  };

  let time = { morning : {start : null,end : null} , morningP2 : {start : null,end : null} , evening: {start : null,end : null} };

  if(category != 'TEXTILE'){
    delete dispos['morningP2'];
    time['morning'] = {start: moment("07:59", "HH:mm"), end: moment("12:00", "HH:mm")},
    time['evening'] = {start: moment("12:01", "HH:mm"), end: moment("18:00", "HH:mm")}
  }else{
      time['morning'] = {start: moment("06:59", "HH:mm"), end: moment("09:00", "HH:mm")},
      time['morningP2'] = {start: moment("08:59", "HH:mm"), end: moment("11:00", "HH:mm")},
      time['evening'] = {start: moment("10:59", "HH:mm"), end: moment("13:00", "HH:mm")}
  }

  // Trier les disponibilites par start_date
  disponibilites.sort((a, b) => {
    return (new Date(a.start_date).getMilliseconds()) - (new Date(b.start_date).getMilliseconds());
  });

  // Récupérer la premeière start_date pour la création du tableau des jours
  let startDate = getDateFromDateTime(start_date);
  [0, 1, 2, 3, 4, 5, 6].forEach((index) => {
    dispos.days.push({
      day: moment(startDate).format('ddd') + ' ' + moment(startDate).format('D'),
      month: moment(startDate).format('D') + ' ' + moment(startDate).format('MMM').toUpperCase(),
      start_date: startDate
    });

    // Récupérer la disponibilité matin si elle est existante
    const dispoMorning = disponibilites.find(item => startDate === getDateFromDateTime(item.start_date)
      && moment(getHourFromDateTime(item.start_date), "HH:mm").isBetween(time.morning.start, time.morning.end, 'hours'));


    const dispoMorningP2 = category != 'TEXTILE' ? undefined : disponibilites.find(item => startDate === getDateFromDateTime(item.start_date)
      && moment(getHourFromDateTime(item.start_date), "HH:mm").isBetween(time.morningP2.start, time.morningP2.end, 'hours'));

    // Récupérer la disponibilité après midi si elle est existante
    const dispoEvening = disponibilites.find(item => startDate === getDateFromDateTime(item.start_date)
      && moment(getHourFromDateTime(item.start_date), "HH:mm").isBetween(time.evening.start, time.evening.end, 'hours'));

    const nextDay = moment(startDate, 'YYYY-MM-DD').day()==5?getDateFromDateTime(moment(startDate, 'YYYY-MM-DD').add(3, 'days').format("YYYY-MM-DD")):getDateFromDateTime(moment(startDate, 'YYYY-MM-DD').add(1, 'days').format("YYYY-MM-DD"));

    const nextDispoMorning = disponibilites.find(item => nextDay === getDateFromDateTime(item.start_date)
      && moment(getHourFromDateTime(item.start_date), "HH:mm").isBetween(time.morning.start, time.morning.end, 'hours'));


    if (!JourFeries.getInstance().estJourFerie(startDate)) {

      dispos.morning.push(buildDispoObject(dispoMorning,nextDispoMorning,startDate,selectedAdresse));

      category == 'TEXTILE' && dispos.morningP2.push(buildDispoObject(dispoMorningP2,nextDispoMorning,startDate,selectedAdresse));

      dispos.evening.push(buildDispoObject(dispoEvening,nextDispoMorning,startDate,selectedAdresse));
      console.log();


    } else {
      let dispo = {
        'type' : 'HOLIDAY',
        'id_disponibilite' : null,
        'start_date' : startDate,
        'est_fermee' : selectedAdresse && selectedAdresse.hasClosedAdr ? moment(startDate).isBetween(selectedAdresse.ferme_du___,selectedAdresse.ferme___au, undefined, '[]') : null,
      };

      category == 'TEXTILE' && dispos.morningP2.push({...dispo});
      dispos.morning.push(dispo);
      dispos.evening.push({...dispo});
    }

    // Incrémenter un jour
    startDate = getDateFromDateTime(moment(startDate, 'YYYY-MM-DD').add(1, 'days').format("YYYY-MM-DD"));
  });

  if(category == 'TEXTILE'){
    dispos.morningP2 = [...dispos.morningP2.filter(i => [6, 0].indexOf(moment(i.start_date).day()) === -1)];
  }

  dispos.morning = [...dispos.morning.filter(i => [6, 0].indexOf(moment(i.start_date).day()) === -1)];
  dispos.evening = [...dispos.evening.filter(i => [6, 0].indexOf(moment(i.start_date).day()) === -1)];
  dispos.days = [...dispos.days.filter(i => [6, 0].indexOf(moment(i.start_date).day()) === -1)];

  return dispos;
}

function buildDispoObject(dispoData,nextDispo,startDate,selectedAdresse){
  let dispo = {};
  if (dispoData && (!nextDispo || (nextDispo && nextDispo.type !== 'FIXEE'))) {
    dispo['id_disponibilite'] = dispoData.id_disponibilite;
    dispo['type'] = dispoData.type;
    dispo['oldDispos'] = dispoData.oldDispos;
    dispo['volume_expired'] = dispoData.volume_expired;
    dispo['nbr_ramassages_expired'] = dispoData.nbr_ramassages_expired;
    dispo['exceptions'] = dispoData.exceptions;
    dispo['is_planifiee'] = dispoData.is_planifiee;
    dispo['start_date_time'] = dispoData.start_date;
  } else if (nextDispo && nextDispo.type === 'FIXEE') {
    dispo['type'] = 'FIXEE';
    dispo['id_disponibilite'] = null;
  } else {
    dispo['type'] = 'EMPTY';
    dispo['id_disponibilite'] = null;
  }

  dispo['start_date'] = startDate;
  dispo['est_fermee'] = selectedAdresse && selectedAdresse.hasClosedAdr ? moment(startDate).isBetween(selectedAdresse.ferme_du___,selectedAdresse.ferme___au, undefined, '[]') : null;

  return dispo;
}

export function addDays(date: string, days: number): string {
  return getDateFromDateTime(moment(date, 'YYYY-MM-DD').add(days, 'days').format("YYYY-MM-DD"));
}

function getHourFromDateTime(date: string): string {
  return moment(date).format("HH:mm");
}

export function getDateFromDateTime(date: string): string {
  return moment(date).format("YYYY-MM-DD");
}

export function thisYear(): string {
  return moment().format("YYYY");
}

export function parseDate(date) {
  return moment(date, "D-M-YYYY").format("DD-MM-YYYY");
}

export function getFirstDate(days,date=null) {
  //si on a une date définie,ajouter le nombre de jours à la date,sinon ajouter le nombre de jours à la date courante
  let firstDate = moment(addDays(date?date:moment().format("YYYY-MM-DD"), days));
  const weekday = firstDate.format('dddd');
  if (weekday === 'Sunday' || weekday === 'dimanche') {
    firstDate = moment(addDays(firstDate.format("YYYY-MM-DD"), 1));
  }
  return firstDate.format("YYYY-MM-DD");
}

export function getDateTimeFrom(date: string, onlyHour: boolean, forceHour:boolean = false) {
  moment.locale('fr');
  const time = {start: moment('07:59', 'HH:mm'), end: moment('12:00', 'HH:mm')};
  const _date = moment(date);

  let horaires = ' en après-midi';
  let hour = '14h00';
  if (moment(_date.format('HH:mm'), 'HH:mm').isBetween(time.start, time.end, 'hours')) {
    horaires = ' en matinée';
    hour = '8h00';
  }
  const datetime = moment(date, 'YYYY-MM-DD HH:mm');
  if (onlyHour) {
    if(forceHour){
      hour = _date.hour()+'h00';
    }
    return datetime.format('LL') + ' à ' + hour;
  }
  return datetime.format('LL') + horaires;
}

export function getDateTimeFromWithoutFormat(date: string, onlyHour: boolean, forceHour:boolean = false) {
  moment.locale('fr');
  const time = {start: moment('07:59', 'HH:mm'), end: moment('12:00', 'HH:mm')};
  const _date = moment(date);
  let horaires = ' après-midi';
  let hour = '14h00';

  if (moment(_date.format('HH:mm'), 'HH:mm').isBetween(time.start, time.end, 'hours')) {
    horaires = ' matin';
    hour = '8h00';
  }
  const datetime = moment(date, 'YYYY-MM-DD HH:mm');
  if (onlyHour) {
    if(forceHour){
      hour = _date.hour()+'h00';
    }
    return datetime.format('DD/MM/YYYY') + ' à ' + hour;
  }
  return datetime.format('DD/MM/YYYY') + horaires;
}

export function getCreatedDate(date: string) {
  return (moment(date, 'YYYY-MM-DD HH:mm')).format('LL') + ' à ' + moment(date).format('HH:mm');
}

export function printDiv(divId) {
  const content = document.getElementById(divId).innerHTML;
  const mywindow = window.open('', 'Print', 'height=600,width=800');
  mywindow.document.write('<html><head><title>Impression</title>');
  mywindow.document.write('<style>/*! CSS Used from: Embedded */\n' +
    '*,::after,::before{box-sizing:border-box; font-family: Arial, sans-serif}\n' +
    'h4{margin-top:0;margin-bottom:.5rem;}\n' +
    'img{vertical-align:middle;border-style:none;}\n' +
    'h4{margin-bottom:.5rem;font-weight:500;line-height:1.2;}\n' +
    'h4{font-size:1.5rem;}\n' +
    '.d-flex{display:-webkit-box!important;display:flex!important;}\n' +
    '.justify-content-center{-webkit-box-pack:center!important;justify-content:center!important;}\n' +
    '.mt-3{margin-top:1rem!important;}\n' +
    '.text-center{text-align:center!important;}\n' +
    '@media print{\n' +
    '*,::after,::before{text-shadow:none!important;box-shadow:none!important;}\n' +
    'img{page-break-inside:avoid;}\n' +
    '}\n' +
    '/*! CSS Used from: Embedded */\n' +
    ':disabled{pointer-events:none!important;}\n' +
    'h4{font-weight:300;}\n' +
    '/*! CSS Used from: Embedded */\n' +
    '::-webkit-scrollbar{width:5px;height:7px;}\n' +
    '::-webkit-scrollbar-button{width:0px;height:0px;}\n' +
    '::-webkit-scrollbar-thumb{background:#525965;border:0px none #ffffff;border-radius:0px;}\n' +
    '::-webkit-scrollbar-thumb:hover{background:#525965;}\n' +
    '::-webkit-scrollbar-thumb:active{background:#525965;}\n' +
    '::-webkit-scrollbar-track{background:transparent;border:0px none #ffffff;border-radius:50px;}\n' +
    '::-webkit-scrollbar-track:hover{background:transparent;}\n' +
    '::-webkit-scrollbar-track:active{background:transparent;}\n' +
    '::-webkit-scrollbar-corner{background:transparent;}\n' +
    '/*! CSS Used from: Embedded */\n' +
    'h4{font-size:1.2rem;font-weight:500;}\n' +
    '*,::after,::before{box-sizing:inherit;color:inherit;}\n' +
    '.informations{font-weight:500;font-style:italic;}\n' +
    '.informations span{display:block;}\n' +
    '.code-resevation{color:#185a61;font-weight:bold;}\n' +
    'qrcode{margin:0 auto;}</style>');
  mywindow.document.write('</head><body >');
  mywindow.document.write(content);
  mywindow.document.write('</body></html>');
  mywindow.document.close();
  mywindow.focus();
  mywindow.print();
  // mywindow.close();
}

export function isValidPicture(file) {
  if (file) {
    const extension = file.name.split('.')[1].toLowerCase();
    if (['png', 'jpg', 'jpeg'].indexOf(extension.toLowerCase()) !== -1) {
      return true;
    }
  }
  return false;
}

export function isValidSheet(file) {
  if (file) {
    const extension = file.name.split('.')[1].toLowerCase();
    if (['xls', 'xlsx', 'csv'].indexOf(extension.toLowerCase()) !== -1) {
      return true;
    }
  }
  return false;
}
